<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page back>
        <el-row>
            <el-col :span="20">
                <el-form
                    ref="addForm"
                    size="small"
                    :model="formModel"
                    :rules="formRules"
                >
                    <el-form-item>
                        <el-button
                            type="success"
                            icon="el-icon-plus"
                            size="small"
                            @click="onAddItem"
                            class="ma-t"
                        >
                            新增
                        </el-button>
                        <el-tabs
                            type="card"
                            closable
                            class="ma-t"
                            @tab-remove="removeTab"
                            v-model="tabIndex"
                            :before-leave="onTabsLeave"
                        >
                            <el-tab-pane
                                v-for="(item,index) in detailList"
                                :label="item.pageName"
                                :key="index"
                                :name="index"
                            >
                                <el-form-item
                                    prop="pageName"
                                    label="页面名称"
                                >
                                    <el-input v-model="item.pageName" />
                                </el-form-item>
                                <el-form-item
                                    label="是否启用"
                                >
                                    <el-switch v-model="item.isAvailable" />
                                </el-form-item>
                            </el-tab-pane>
                        </el-tabs>
                        <PageDecorator
                            ref="pageDecorator"
                            :available-components="[
                                'Divider',
                                'Toolbar',
                                'Ad',
                                'AdCube',
                                'MagicCube',
                                'RichText',
                                'CustomCode',
                                'Video',
                            ]"
                            :json="detailList[tabIndex].pageContent"
                        />
                    </el-form-item>
                    <el-form-item
                        label-width="13em"
                    >
                        <el-button
                            type="primary"
                            size="small"
                            @click="onConfirm"
                        >
                            保 存
                        </el-button>
                        <el-button
                            size="small"
                            @click="$router.back()"
                        >
                            返 回
                        </el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';

export default {
    name: 'MarketCodeSource',
    mixins: [pagesMixin],
    data() {
        return {
            tabIndex: 0,
            // 表单数据
            formModel: {
                id: '',
            },
            // 表单校验规则
            formRules: {},
            detailList: [
                {
                    pageName: '新增页签1',
                    isAvailable: true,
                    pageContent: '[{"componentType":"Page","props":{"backgroundColor":"#fff","title":"新增页签1"}}]',
                },
            ],
        };
    },
    methods: {
        onConfirm() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                const {
                    tabIndex,
                    detailList,
                    $refs: { pageDecorator },
                } = this;
                const jsonData = pageDecorator.getDecorationJson();
                detailList[tabIndex].pageContent = jsonData;
                this.$api.Ts.MarketCode.saveSource({
                    ...this.formModel,
                    detailJson: JSON.stringify(this.detailList),
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    // this.$router.back();
                });
            });
        },
        init() {
            this.$api.Ts.MarketCode.getSource({ id: this.formModel.id }).then(json => {
                const res = json.data.data;
                if (res.length !== 0) {
                    this.detailList = res;
                }
            });
        },
        onAddItem() {
            const pageName = `新增页签${this.detailList.length + 1}`;
            this.detailList.push({
                pageName,
                isAvailable: true,
                pageContent: `[{"componentType":"Page","props":{"backgroundColor":"#fff","title":"${pageName}"}}]`,
            });
        },
        removeTab(targetName) {
            this.detailList.splice(+targetName, 1);
        },
        onTabsLeave(val, oldVal) {
            if (oldVal === undefined) return false;
            const {
                tabIndex,
                detailList,
                $refs: { pageDecorator },
            } = this;
            if (!pageDecorator) return false;
            detailList[tabIndex].pageContent = pageDecorator.getDecorationJson();
            return true;
        },
    },
    created() {
        this.formModel.id = this.$route.params.id;
        if (this.formModel.id) {
            this.init();
        }
    },
};
</script>

<style lang="scss">
</style>
